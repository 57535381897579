import React from 'react'

export default function ComingSoon() {
  return (
    <div className='bg-black w-full h-screen'>
      <h1 className='text-white pt-40 text-4xl md:text-6xl'>ioTech phone repair</h1>
      <h3 className='text-white pt-8 text-xl md:text-2xl'>coming soon</h3>
    </div>
  )
}
