import logo from './logo.svg';
import './App.css';
import './Components/ComingSoon';
import Coming from './Components/ComingSoon';

function App() {
  return (
    <div className="App">
      <Coming/>
    </div>
  );
}

export default App;
